export const ADD_BUILDING = "ADD_BUILDING";
export const CHANGE_NEW_BUILDING = "CHANGE_NEW_BUILDING"
export const CHANGE_NEW_UNIT_COUNT = "CHANGE_NEW_UNIT_COUNT"
export const CHANGE_NEW_UNIT = "CHANGE_NEW_UNIT"
export const CHANGE_OWNER = "CHANGE_OWNER"
export const CREATE_DOCUMENTS = "CREATE_DOCUMENTS";
export const CHANGE_SELECTED_BUILDING = "CHANGE_SELECTED_BUILDING"
export const GENERIC_STATE_CHANGE = "GENERIC_STATE_CHANGE"
export const FETCH_ALL_BUILDING_SUCCESS = "FETCH_ALL_BUILDING_SUCCESS"
export const TOKEN_RECEIVED = "TOKEN_RECEIVED"
export const TOKEN_RECEIVED_MANAGER = "TOKEN_RECEIVED_MANAGER"
export const TENANT_BUILDING_RECEIVED = "TENANT_BUILDING_RECEIVED"
export const TENANT_VOTING_RECEIVED = "TENANT_VOTING_RECEIVED"
export const UNAUTHENTICATED = "UNAUTHENTICATED"


//TBD:
// ChangeTenant/Owner
// SubmitIssue
// AddProposal
// Vote

export function genericAction(type, payload){
    return {type, payload}
}

// export function tokenReceived(jwt, role){
//     return genericAction(TOKEN_RECEIVED, {jwt, role})
// }

// export function

// export function tenantBuildingReceived(building){
//     return genericAction(TENANT_BUILDING_RECEIVED, building)
// }

//TODO: this should be called when is already added
export function addBuilding(building) {
    return {
        type: ADD_BUILDING,
        payload: building
    }
}

export function changeNewBuilding(updateData) {
    return {
        type: CHANGE_NEW_BUILDING,
        payload: updateData
    }
}

export function changeNewUnitCount(count) {
    return {
        type: CHANGE_NEW_UNIT_COUNT,
        payload: count
    }
}

export function changeNewUnit(updateData) {
    return {
        type: CHANGE_NEW_UNIT,
        payload: updateData
    }
}

export function changeNewUnitOwner(updateData) {
    return {
        type: CHANGE_OWNER,
        payload: updateData
    }
}

export const fetchBuildingsSuccess = buildings => ({
    type: FETCH_ALL_BUILDING_SUCCESS,
    payload: { buildings }
});

//TODO: this should not be called by reducer
export function createDocuments(building) {
    // console.log(building)
    return {
        type: CREATE_DOCUMENTS,
        payload: building
    }
}

export function changeSelectedBuilding(buildingId, selectedIndex) {
    return {
        type: CHANGE_SELECTED_BUILDING,
        payload: { selectedBuilding: buildingId, selectedBuildingIndex: selectedIndex - 1 }
    }
}

export function genericStateChange(key, value) {
    return {
        type: GENERIC_STATE_CHANGE,
        payload: { key, value }
    }
}


